import React from "react";
import "./CapabilityCards.scss";
import { ImagePass } from "../../Image/ImagePass";
import RenderContent from "../../RenderContent";
import GatsbyLink from "../../GatsbyLink";

export const CapabilityCards = ({
  backgroundImage,
  cards,
  cardStyle,
  content,
}) => {
  return (
    <section
      className={`capabilityCards-component capabilityCards-component--${cardStyle}`}
    >
      <div className="wrapper">
        <div className="inner">
          <RenderContent
            content={content}
            className="capabilityCards-content"
          />
          <div className="capabilityCards-grid">
            {Object.keys(cards).length > 0 &&
              cards?.map((card, index) => {
                return (
                  <GatsbyLink
                    to={card?.link?.url}
                    className="capabilityCards-card"
                    key={`card-${index}`}
                  >
                    <RenderContent content={card.content} />
                  </GatsbyLink>
                );
              })}
          </div>
        </div>
      </div>
      <ImagePass src={backgroundImage} className="background-image" />
    </section>
  );
};
