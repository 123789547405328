import React from "react";
import { ImagePass } from "../../Image/ImagePass";
import "./ImageSlider.scss";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import useSwiperRef from "../../../hooks/useSwiperRef";
// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.css";

export const ImageSlider = ({ images }) => {
  const [nextEl, nextElRef] = useSwiperRef();
  const [prevEl, prevElRef] = useSwiperRef();
  const defaultSettings = {
    className: "infoSlider",
    slidesPerView: 1,
    spaceBetween: 16,
    navigation: true,
    // navigation: {
    //   nextEl: nextEl,
    //   prevEl: prevEl,
    // },
  };
  return (
    <section className="image-slider-component">
      <div className="wrapper">
        <Swiper {...defaultSettings}>
          {images &&
            images.map((image, index) => {
              return (
                <SwiperSlide key={`slide_${index}`}>
                  <div className="image-container">
                    <ImagePass src={image?.image} />
                  </div>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
    </section>
  );
};

ImageSlider.defaultProps = {
  images: [
    {
      image: {
        sourceUrl:
          "https://i.picsum.photos/id/866/552/308.jpg?hmac=2WfF0WhmLjTyO4Ij8NUPtuucwuaEdaW_f9bgdhRyoGQ",
      },
    },
    {
      image: {
        sourceUrl:
          "https://i.picsum.photos/id/1053/552/308.jpg?hmac=NDVBgt1DDhmV_xnPH7ABTcdefHMtLLBgyuPJZ0oFKRI",
      },
    },
    {
      image: {
        sourceUrl:
          "https://i.picsum.photos/id/368/552/308.jpg?hmac=dBJEyc1okKjOxyVhJ7wKuTktSuYm7cuLGDFJqb7i19c",
      },
    },
  ],
};
