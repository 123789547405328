import React, { useState, useEffect } from "react";
import { ImagePass } from "../../Image/ImagePass";
import RenderContent from "../../RenderContent";
import "./UnderHero.scss";

export const UnderHero = (props) => {
  const { heading, leftContent, rightContent, image, location } = props;

  const [isHomePage, setHomePage] = useState(false);

  useEffect(() => {
    location?.pathname === "/" ? setHomePage(true) : setHomePage(false);
  }, [location]);

  return (
    <section
      className={`under-hero--component${isHomePage ? " homepage" : ""}`}
    >
      <div className="wrapper">
        <div className="inner">
          {heading && (
            <RenderContent
              className="heading"
              content={heading}
            ></RenderContent>
          )}
          {leftContent && rightContent && (
            <div className="two-col-content">
              <RenderContent
                className="left-content"
                content={leftContent}
              ></RenderContent>
              <RenderContent
                className="right-content"
                content={rightContent}
              ></RenderContent>
            </div>
          )}
          {image && (
            <div className="main-image-wrapper">
              <ImagePass src={image} lazy={false} />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

UnderHero.defaultProps = {
  heading:
    "<span><h2>Contact Us</h2><h3>Get in touch today</h3><p>If you are looking for a professional, agile and attentive team to deliver all your needs – whether it is a small or large scale project – we would love to receive your invitation to tender.</p></span> ",
  leftContent: "<p>This is the text content example</p>",
  rightContent: "<p>This is the text content example</p>",
  image: "https://via.placeholder.com/430",
};
