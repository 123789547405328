import React from "react";
import { ImagePass } from "../../Image/ImagePass";
import { Button } from "../../Button";
import RenderContent from "../../RenderContent";
import "./ImageAndContent.scss";

export const ImageAndContent = ({
  textPosition,
  imagePath,
  content,
  cta,
  style,
}) => {
  return (
    <div
      className={`image-and-content image-and-content--${style} image-and-content--${textPosition}`}
    >
      <div className="image-and-content--inner">
        <div className="text-part">
          <RenderContent className="content" content={content} />
          <div className="cta-button">
            {cta ? (
              <Button buttonLink={cta?.url} buttonText={cta?.title} />
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="image-part">
          <div className="image-wrapper">
            <ImagePass src={imagePath} className="image-img" />
          </div>
        </div>
      </div>
    </div>
  );
};

ImageAndContent.defaultProps = {
  textPosition: "left",
  imagePath: "https://via.placeholder.com/430",
  content: "<p>This is the text content example</p>",
  cta: {
    title: "Default link",
    url: "/",
  },
};
