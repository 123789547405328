import React from "react";

export const Phone = (props) => {
  const { field, fieldId } = props;
  const { placeholder, isRequired, cssClass, id } = field;
  // Gravity Form field ID ~ name.
  const gfName = `input_${id}`;
  return (
    <div
      className={`${cssClass ? ` ${cssClass}` : ""}
        ${isRequired ? " required" : ""}`}
    >
      <label htmlFor={fieldId}>{placeholder}</label>
      <input
        name={gfName}
        id={fieldId}
        placeholder={placeholder}
        required={!!isRequired}
        type="phone"
        pattern="^[0-9]+$"
        maxLength={10}
        minLength={10}
      />
    </div>
  );
};
