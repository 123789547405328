import React from "react";
import LazyLoad from "react-lazy-load";
import RenderContent from "../../RenderContent";
import "./ContactDetail.scss";

export const ContactDetail = ({ leftContent, rightContent, iframe }) => {
  return (
    <section className="contact-detail--component">
      <div className="wrapper">
        <div className="inner">
          {leftContent && rightContent && (
            <div className="two-col-content">
              <RenderContent
                className="left-content"
                content={leftContent}
              ></RenderContent>
              <RenderContent
                className="right-content"
                content={rightContent}
              ></RenderContent>
            </div>
          )}
          {iframe && (
            <div className="iframe-block">
              <LazyLoad>
                <iframe src={iframe} frameBorder="0" allowFullScreen />
              </LazyLoad>
              <div className="loading" />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
