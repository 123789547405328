import React, { Component } from "react";
import { Button } from "../../Button";
import RenderContent from "../../RenderContent";
import "./RelatedPass.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import useSwiperRef from "../../../hooks/useSwiperRef";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.css";
import { ProjectListItem } from "../../Blog/ProjectListItem";

SwiperCore.use([Navigation]);

export const RelatedPass = (props) => {
  const [nextEl, nextElRef] = useSwiperRef();
  const [prevEl, prevElRef] = useSwiperRef();

  const { data, content, contentType = "post" } = props;
  const {
    allWordpressPost,
    categoryFilter,
    allWordpressWpProjects,
    wordpressWpSettings,
    allWordpressCategory,
    siteSettings,
    index,
  } = data;
  const postPass = allWordpressWpProjects
    ? allWordpressWpProjects
    : allWordpressPost;
  const { edges: posts } = postPass;
  const pathPrefix =
    contentType === "project" ? "projects" : wordpressWpSettings.blogSlug;
  const defaultSettings = {
    className: "infoSlider_" + index,
    slidesPerView: 1,
    spaceBetween: 16,
    navigation: {
      nextEl: nextEl,
      prevEl: prevEl,
    },
    breakpoints: {
      700: {
        slidesPerView: "auto",
      },
    },
  };
  return (
    <section className="related-posts-component layout-right-flow">
      <div className="related-posts-component__wrapper">
        <div className="left-side">
          <div className="main-content">
            {content ? (
              <div className="content">
                <RenderContent content={content}></RenderContent>
              </div>
            ) : (
              <div className="title">
                <h2>Related Projects</h2>
              </div>
            )}
          </div>
        </div>
        <div className="right-side">
          <Swiper {...defaultSettings}>
            {posts &&
              posts.map(
                (post, i) =>
                  post.node.slug !== "gatsby" && (
                    <SwiperSlide key={`slide_${i}`}>
                      <ProjectListItem
                        data={post.node}
                        siteMetadata={wordpressWpSettings}
                        pathPrefix={pathPrefix}
                      />
                    </SwiperSlide>
                  )
              )}
          </Swiper>

          <div className="nav-helper">
            <button className="swiper-button-prev" ref={prevElRef} />
            <button className="swiper-button-next" ref={nextElRef} />
          </div>
        </div>
      </div>
    </section>
    // <section className="slider-component">
    //   <div className="inner">
    //     <div className="left-side">
    //       <div className="main-content">
    //         {content ? (
    //           <div className="content">
    //             <RenderContent content={content}></RenderContent>
    //           </div>
    //         ) : (
    //           <div className="title">
    //             <h2>Related Projects</h2>
    //           </div>
    //         )}
    //       </div>
    //     </div>
    //     <div className="right-side">
    //       <Swiper
    //         className="mySwiper"
    //         centerInsufficientSlides={true}
    //         spaceBetween={20}
    //         slidesPerView={1}
    //         navigation={{
    //           prevEl,
    //           nextEl,
    //         }}
    //         breakpoints={{
    //           1080: {
    //             slidesPerView: 1.2,
    //             spaceBetween: 30,
    //           },
    //         }}
    //       >
    //         {posts &&
    //           posts.map(
    //             (post, i) =>
    //               post.node.slug !== "gatsby" && (
    //                 <SwiperSlide key={`slide_${i}`}>
    //                   <ProjectListItem
    //                     data={post.node}
    //                     siteMetadata={wordpressWpSettings}
    //                     pathPrefix={pathPrefix}
    //                   />
    //                 </SwiperSlide>
    //               )
    //           )}
    //       </Swiper>
    //       <div className="nav-helper">
    //         <button className="swiper-button-prev" ref={prevElRef} />
    //         <button className="swiper-button-next" ref={nextElRef} />
    //       </div>
    //     </div>
    //   </div>
    // </section>
  );
};
