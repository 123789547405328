import React from "react";
import PropTypes from "prop-types";
import { ImagePass } from "../../Image/ImagePass";
import RenderContent from "../../RenderContent";
import "./TeamMembers.scss";

export const TeamMembers = ({ teamMembers }) => {
  return (
    <section className="team-members-component">
      <div className="inner">
        {teamMembers &&
          teamMembers?.map((teamMember, index) => (
            <div className="team-member" key={index}>
              <div className="team-member--wrapper">
                <div className="team-member--image-wrapper">
                  <ImagePass
                    src={teamMember.image}
                    className="team-member--image"
                  />
                </div>
                <div className="team-member--name">{teamMember.name}</div>
                <div className="team-member--title">{teamMember.title}</div>
                <RenderContent
                  className="team-member--content"
                  content={teamMember.content}
                />
              </div>
            </div>
          ))}
      </div>
    </section>
  );
};

TeamMembers.propTypes = {
  teamMembers: PropTypes.array,
};
