import React from "react";
import RenderContent from "../../RenderContent";
import "./ProjectDetails.scss";

export const ProjectDetails = ({
  topLeftContent,
  location,
  duration,
  client,
  cost,
  bottomLeftContent,
  bottomRightContent,
}) => {
  return (
    <section className="project-details--component">
      <div className="wrapper">
        <div className="inner">
          <div className="top-left">
            <RenderContent className="content" content={topLeftContent} />
          </div>
          <div className="top-right">
            <div className="details-wrapper">
              <div className="title">Client</div>
              <div className="data">{client}</div>
              <div className="title">Location</div>
              <div className="data">{location}</div>
              <div className="title">Duration</div>
              <div className="data">{duration}</div>
              <div className="title">Cost</div>
              <div className="data">{cost}</div>
            </div>
          </div>

          <div className="bottom-left">
            <RenderContent className="content" content={bottomLeftContent} />
          </div>
          <div className="bottom-right">
            <RenderContent className="content" content={bottomRightContent} />
          </div>
        </div>
      </div>
    </section>
  );
};

ProjectDetails.defaultProps = {
  heading: "Default heading",
  boldText: "Default bold text",
  content: "<p>This is the text content example</p>",
  detailRows: [
    {
      title: "Location",
      data: "Western Australia",
    },
  ],
};
