import React from "react";
import GatsbyLink from "../../GatsbyLink";
import RenderContent from "../../RenderContent";
import { ImagePass } from "../../Image/ImagePass";
import { Button } from "../../Button";
import "./ProjectListItem.scss";
import { decodeEntities } from "../../../utils/helpers";
import { dateI18n } from "@wordpress/date";

export const ProjectListItem = (props) => {
  const { siteMetadata, data, pathPrefix, showAuthor, dateFormat } = props;
  const { excerpt, title, slug, path, featured_media, author, date, acf } =
    data;
  const dateFormatted = dateFormat && date && dateI18n(dateFormat, date);
  const projectDetails = acf?.layout?.filter(
    (i) => i.__typename === "WordPressAcf_ProjectDetails"
  );
  const projectClient = projectDetails?.[0]?.client;
  const projectLocation = projectDetails?.[0]?.location;

  return (
    <div className="project-list-item">
      <div className="image-wrapper">
        <ImagePass src={featured_media} />
      </div>
      <div className="project-list-item--inner">
        <GatsbyLink to={path} label={title}>
          <div className="title">{title}</div>
        </GatsbyLink>

        {projectClient && (
          <div className="client-and-location">
            <div className="client">{projectClient}</div>

            {projectLocation && (
              <div className="location">{projectLocation}</div>
            )}
          </div>
        )}

        {excerpt && (
          <RenderContent className="content" content={`${excerpt}`} />
        )}
        <div className="cta-button">
          {slug ? <Button buttonLink={path} buttonText="View Project" /> : ""}
        </div>
      </div>
    </div>
  );
};
