import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import { RelatedPass } from "./RelatedPass";

const FilteredProjects = (props) => {
  const {
    data: {
      allWordpressWpProjects: { edges: allProjects },
    },
    postfilter,
  } = props;
  const filtered = allProjects.filter((project) =>
    postfilter.find((x) => x.wordpress_id === project.node.wordpress_id)
  );

  return (
    <RelatedPass
      {...props}
      data={{ allWordpressWpProjects: { edges: filtered } }}
    />
  );
};

const RelatedProjects = (props) => {
  const postType = props.contentType ? props.contentType : "post";
  return (
    <StaticQuery
      //Look to update this query when dynamic variables become available https://spectrum.chat/gatsby-js/general/using-variables-in-a-staticquery~abee4d1d-6bc4-4202-afb2-38326d91bd05
      query={graphql`
        query {
          wordpressWpSettings {
            blogSlug
          }
          siteSettings: wordpressAcfOptions(options: {}) {
            options {
              showAuthor
            }
          }
          allWordpressWpProjects(sort: { fields: date, order: DESC }) {
            edges {
              node {
                ...ProjectListFields
              }
            }
          }
        }
      `}
      render={(data) => <FilteredProjects {...props} data={data} />}
    />
  );
};

export default RelatedProjects;
