import React from "react";
import RenderContent from "../../RenderContent";
import "./Callout.scss";

export const Callout = ({ heading, listItems }) => {
  return (
    <section className="callout-component">
      <div className="wrapper">
        <div className="inner">
          <RenderContent className="heading" content={heading} />
          {listItems && (
            <div className="list-items">
              {Object.keys(listItems).length > 0 &&
                listItems?.map((item, index) => {
                  return (
                    <div className="list-item" key={index}>
                      {item.listItem}
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

Callout.defaultProps = {
  heading: "<p>This is the heading example</p>",
  listItems: [
    { listItem: "List Item example" },
    { listItem: "List Item example" },
    { listItem: "List Item example" },
  ],
};
