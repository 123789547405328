import React from "react";
import { ImagePass } from "../../Image/ImagePass";
import RenderContent from "../../RenderContent";
import "./MultiContentAndImage.scss";

export const MultiContentAndImage = ({ imagePath, header, infoBlocks }) => {
  return (
    <div className="multi-content-and-image">
      <div className="multi-content-and-image--top">
        <div className="text-part">
          <RenderContent className="header" content={header} />
        </div>
        <div className="image-part">
          <div className="image-wrapper">
            <ImagePass src={imagePath} className="image-img" />
          </div>
        </div>
      </div>
      <div className="multi-content-and-image--bottom">
        {infoBlocks &&
          infoBlocks.map((block, index) => {
            return (
              <div className="infoblock-wrapper" key={index}>
                <RenderContent
                  className="infoblock"
                  content={block.infoBlock}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

MultiContentAndImage.defaultProps = {
  imagePath: "https://via.placeholder.com/430",
  header: "<p>This is the text content example</p>",
  infoBlocks: [
    { infoBlock: "<h3>This is an infoblock example</h3>" },
    { infoBlock: "<h3>This is an infoblock example</h3>" },
  ],
};
