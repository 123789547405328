/* eslint-disable no-console */
import React from "react";
import RenderContent from "../../RenderContent";
import { ImagePass } from "../../Image/ImagePass";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import useSwiperRef from "../../../hooks/useSwiperRef";
// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.css";
import "./InfoSlider.scss";

SwiperCore.use([Navigation]);

export const InfoSlider = ({
  content,
  colourTheme,
  cardStyle,
  cardContent,
  index,
}) => {
  const [nextEl, nextElRef] = useSwiperRef();
  const [prevEl, prevElRef] = useSwiperRef();
  const defaultSettings = {
    className: "infoSlider_" + index,
    slidesPerView: 1,
    spaceBetween: 16,
    navigation: {
      nextEl: nextEl,
      prevEl: prevEl,
    },
    centerInsufficientSlides: true,
    breakpoints: {
      700: {
        slidesPerView: "auto",
      },
    },
  };

  return (
    <section className="info-slider-component layout-right-flow">
      <div className="info-slider-component__wrapper">
        <div className={`inner inner--${colourTheme}`}>
          <div className="left-side">
            <RenderContent content={content} />
          </div>
          <div className="right-side">
            <Swiper {...defaultSettings}>
              {cardContent &&
                cardContent?.map((card, i) => (
                  <SwiperSlide
                    key={`slide_${i}`}
                    className={`swiper-slide--${cardStyle}`}
                  >
                    <div className="image-wrapper">
                      <ImagePass src={card.imagePath} className="image-img" />
                    </div>
                    <div
                      className={`info-slide--content info-slide--content--${colourTheme}`}
                    >
                      <RenderContent content={card.textContent} />
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>

            <div className="nav-helper">
              <button className="swiper-button-prev" ref={prevElRef} />
              <button className="swiper-button-next" ref={nextElRef} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
