import React from "react";
import "./Header.scss";
import { ImagePass } from "../../Image/ImagePass";
import RenderContent from "../../RenderContent";
import ReactPlayer from "react-player";
import { Link as ScrollToLink } from "react-scroll";

export const Header = ({ header, image, video, compact }) => {
  return (
    <section className={`page-header${compact ? " compact" : ""}`}>
      <div className="inner">
        <div className="header">
          <RenderContent content={header} />
          {!compact && (
            <ScrollToLink
              to="homepage-first-under-hero-component"
              spy={true}
              smooth={true}
              duration={500}
              offset={-75}
              className="scroll-link"
            >
              Scroll down
            </ScrollToLink>
          )}
        </div>
      </div>
      {video ? (
        <div className="player-wrapper">
          <ReactPlayer
            className="react-player"
            url={video}
            volume={0}
            muted={true}
            playing={true}
            loop={true}
            controls={false}
            width="100%"
            height="970px"
            // autoHeight
          />
        </div>
      ) : (
        <div className="image-wrapper">
          <ImagePass src={image} lazy={false} />
        </div>
      )}
    </section>
  );
};
