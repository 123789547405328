import React from "react";
import { ImagePass } from "../../Image/ImagePass";
import { GravityForm, gravityFormExtractor } from "../../gravity-form";
import { useAllGravityForms } from "../../../hooks/useAllGravityForms";
import "./ContactFormBlock.scss";

export const ContactFormBlock = ({ imagePath, formId }) => {
  // Get all of our Gravity Forms
  const gravityFormsData = useAllGravityForms();
  // Retrieve our form and formfields

  const gravityFormId = parseInt(formId);
  const formData = gravityFormExtractor(gravityFormId, gravityFormsData);

  return (
    <section className="contact-form-block-component">
      <div className="contact-form-block-component--inner">
        {gravityFormId && (
          <div className="form-wrapper">
            {gravityFormsData && (
              <GravityForm
                formData={formData}
                dataLayerEvent={"form_submit_success"}
                displayTitle={false}
                successMessage={`Thank you for your message! We will get back to you as soon as possible.`}
              />
            )}
          </div>
        )}
        <div className="image-part">
          <div className="image-wrapper">
            <ImagePass src={imagePath} className="image-img" />
          </div>
        </div>
      </div>
    </section>
  );
};

ContactFormBlock.defaultProps = {
  imagePath: "https://via.placeholder.com/430",
  formId: 1,
};
