import React from "react";

export const Checkbox = (props) => {
  const { field, fieldId } = props;
  const { isRequired, className, id, choices } = field;
  const options = JSON.parse(choices);
  // Gravity Form field ID ~ name.
  const gfName = `input_${id}`;
  return (
    <div
      className={`${className ? ` ${className}` : ""}
        ${isRequired ? " required" : ""}`}
    >
      <div className="checkboxes">
        {options &&
          options.map((option, index) => (
            <label
              htmlFor={`${fieldId}-${index}`}
              className="checkbox"
              key={index}
            >
              <span>{option.text}</span>
              <input
                // index starts from 1 for gravity form
                name={`${gfName}_${index + 1}`}
                id={`${fieldId}-${index}`}
                type="checkbox"
                value={option.value}
              />
            </label>
          ))}
      </div>
    </div>
  );
};
